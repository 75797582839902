import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import WebIcon from '@mui/icons-material/Web';
import HouseIcon from '@mui/icons-material/House';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function getIcon(icon) {
  switch (icon) {
    case 'web':
      return <WebIcon />;
    case 'home':
      return <HouseIcon />;
    case 'rules':
      return <DataThresholdingIcon />;
    case 'account':
      return <PersonOutlineIcon />;
    default:
      return <WebIcon />;
  }
}

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{getIcon(icon)}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
