import { axiosInstance } from './common';

export const getAllRules = () => axiosInstance.get(`/rule/list`).then((response) => response.data);

export const createRule = (rule) => axiosInstance.post(`/rule/save`, rule).then((r) => r.data);

export const getRule = (ruleId) => axiosInstance.get(`/rule/${ruleId}`).then((response) => response.data);

export const deleteRule = (ruleId) => {
  axiosInstance.delete(`/rule/${ruleId}`).then((response) => response.data);
};
