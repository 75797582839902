import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertTitle,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  List,
  ListItem,
} from '@mui/material';
import { getSensorType } from '../../../services/localizationService';

StandardEditDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  handleDelete: PropTypes.func,
  sensorTypes: PropTypes.array,
  standardData: PropTypes.object,
};

const mapOperator = (operator) => {
  switch (operator) {
    case 'greater':
      return '>';
    case 'lesser':
      return '<';
    case 'equal':
      return '=';
    default:
      return '';
  }
};

function StandardEditDialog({ open, handleClose, handleSave, sensorTypes, standardData, handleDelete }) {
  const [name, setName] = useState('');
  const [operator, setOperator] = useState('');
  const [sensorType, setSensorType] = useState('');
  const [value, setValue] = useState('');
  const [sensorError, setSensorError] = useState(false);
  const [operatorError, setOperatorError] = useState(false);
  const [valueRequiredError, setValueRequiredError] = useState(false);
  const [valueTypeError, setValueTypeError] = useState(false);

  const close = () => {
    handleClose();
    setSensorError(false);
    setOperatorError(false);
    setValueRequiredError(false);
    setValueTypeError(false);
  };

  useEffect(() => {
    setName(standardData.name);
    setOperator(standardData.operator);
    setSensorType(standardData.sensorType);
    setValue(standardData.value);
  }, [standardData]);

  const validateField = (field, cb, isValueCheck = false) => {
    const truthyCheck = isValueCheck ? isNaN(field) : field === '';

    if (truthyCheck) {
      cb(true);
      return true;
    }

    cb(false);
    return false;
  };

  const validateForm = () => {
    const sensor = validateField(sensorType, setSensorError);
    const op = validateField(operator, setOperatorError);
    const valReq = validateField(value, setValueRequiredError);
    const valType = validateField(value, setValueTypeError, true);

    return sensor || op || valReq || valType;
  };

  const handleSubmit = async () => {
    if (validateForm()) return;
    const input = {
      id: standardData.id,
      name: name || `${getSensorType(sensorType)} ${mapOperator(operator)} ${value}`,
      description: '',
      operator,
      sensorType,
      color: '#000000',
      icon: '',
      value: parseFloat(value),
    };

    handleSave(input);
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Update Rule</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Typography variant="h7" sx={{ mb: 1 }}>
            Sensor Type *
          </Typography>
          <br />
          <Select
            title="Sensor Type"
            margin="dense"
            fullWidth
            value={sensorType}
            onChange={(e) => {
              setSensorType(e.target.value);
            }}
            error={sensorError}
          >
            {sensorTypes.map((sensorType) => (
              <MenuItem key={`create-standard-${sensorType}`} value={sensorType}>
                {getSensorType(sensorType)}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="h7" sx={{ mb: 1 }}>
            Operator *
          </Typography>
          <Select
            required
            title="Operator"
            margin="dense"
            fullWidth
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
            error={operatorError}
          >
            <MenuItem value={'greater'}>Greater Than</MenuItem>
            <MenuItem value={'lesser'}>Less Than</MenuItem>
            <MenuItem value={'equal'}>Equal</MenuItem>
          </Select>
          <Typography variant="h7" sx={{ mb: 1 }}>
            Value *
          </Typography>
          <TextField
            margin="dense"
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
            error={valueRequiredError || valueTypeError}
          />
          <Typography variant="h7" sx={{ mb: 1 }}>
            Name
          </Typography>
          <TextField
            margin="dense"
            label=""
            placeholder={
              sensorType && operator && value
                ? `${getSensorType(sensorType)} ${mapOperator(operator)} ${value}`
                : 'Name'
            }
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
        </form>
        {(sensorError || operatorError || valueRequiredError || valueTypeError) && (
          <Alert variant="filled" severity="error" sx={{ mt: 1 }}>
            <AlertTitle>Error</AlertTitle>
            <List>
              {sensorError && <ListItem disableGutters>Sensor Type is Required</ListItem>}
              {operatorError && <ListItem disableGutters>Operator is Required</ListItem>}
              {valueRequiredError && <ListItem disableGutters>Value is Required</ListItem>}
              {valueTypeError && <ListItem disableGutters>Value must be a number</ListItem>}
            </List>
          </Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', px: '20px', pb: '20px' }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleDelete(standardData.id);
            close();
          }}
        >
          Delete
        </Button>
        <div></div>
        <div>
          <Button onClick={close}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ ml: '8px' }}>
            Update
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default StandardEditDialog;
