import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import StandardCard from './StandardsPageSidebar';

// ----------------------------------------------------------------------

StandardsList.propTypes = {
  standards: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
};

export default function StandardsList({ standards, onEdit, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {standards.map((standard) => (
        <Grid key={standard.id} item xs={12} sm={6} md={3}>
          <StandardCard standard={standard} onEdit={onEdit} />
        </Grid>
      ))}
    </Grid>
  );
}
