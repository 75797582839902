import { Helmet } from 'react-helmet-async';
import react, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
// components
import { getSignedInUser, signOut } from '../services/authService';

export default function AccountPage() {
  const navigate = useNavigate();

  const user = getSignedInUser();
  const [value, setValue] = useState(user.displayTemperatureAsFahrenheit);

  function handleChange(e) {
    const displayTemperatureAsFahrenheit = e.currentTarget.value === 'true';
    setValue(displayTemperatureAsFahrenheit);
    const updatedUser = {
      ...user,
      displayTemperatureAsFahrenheit,
    };
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }

  function handleReset() {
    setValue(false);
  }

  function onLogoutClick() {
    signOut();
    navigate('/login');
  }

  return (
    <>
      <Helmet>
        <title> VPSI | Account </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Account
          </Typography>
        </Stack>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader title="Information" />
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <strong>Username:</strong> {user.username}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  <strong>Email:</strong> {user.email}:
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader title="Settings (Coming Soon)" />
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Manage your account settings and set email preferences.
                </Typography>
                <FormControl sx={{ my: 2 }} focused={false}>
                  <FormLabel>Temperature Scale</FormLabel>
                  <RadioGroup value={value} onChange={handleChange}>
                    <FormControlLabel value={false} control={<Radio />} label="Celsius" />
                    <FormControlLabel value={true} control={<Radio />} label="Fahrenheit" />
                  </RadioGroup>
                </FormControl>
                <Container disableGutters>
                  <Button variant="contained" color="error" onClick={handleReset}>
                    Reset Default
                  </Button>
                </Container>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Card>
              <CardHeader title="Actions" />
              <CardContent>
                <br />
                <Button variant="contained" color="primary" fullWidth onClick={() => onLogoutClick()}>
                  Logout
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
