/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-continue */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router-dom';

// @mui
import { Container, Grid, Button, TextField } from '@mui/material';

// components
import { getSignedInUser } from '../services/authService';
import { getDataCapture, getStreamingLink } from '../services/dataCaptureService';
import { requestTranscription, retriveTranscription } from '../services/transcriptionService';
import { createRule, getAllRules } from '../services/ruleService';

import {
  SessionPageChartTabbedDisplay,
  SessionPageHeader,
  SessionPageVideoPlayer,
  SessionDetailsCard,
  SessionEventTimeLine,
} from '../sections/@dashboard/session';
import { StandardCreationDialog } from '../sections/@dashboard/standards';

const MAX_GRAPH_COUNT = 25;

// ----------------------------------------------------------------------
export default function DataCapturePage() {
  const { dataCaptureId } = useParams();
  const videoRef = useRef(null);
  const user = getSignedInUser();

  const [currentTime, setCurrentTime] = useState(new Date());
  const [dataCapture, setDataCapture] = useState([]);
  const [loading, setLoading] = useState(true);
  const [graphCount, setGraphCount] = useState(1);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [stepperActiveStep, setStepperActiveStep] = useState(0);
  const [standards, setStandards] = useState([
    { name: 'test', id: 1, description: 'test', type: 'greater', value: 0.5 },
  ]);
  const [sensorEvents, setSensorEvents] = useState([]);
  const [allSensorData, setAllSensorData] = useState({});
  const [sensorsByType, setSensorsByType] = useState({});
  const [activeStandards, setActiveStandards] = useState({});
  const [lagTime, setLagTime] = useState(5000);
  const [initalStartAndEndEvents, setInitalStartAndEndEvents] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [createStandardModalShown, setCreateStandardModalShown] = useState(false);
  const [videoStreamingLink, setVideoStreamingLink] = useState('');
  const [transcription, setTranscription] = useState('');
  const [eventSensor, setEventSensor] = useState(null);

  useHotkeys('space', (e) => {
    e.preventDefault();
    setIsPlaying(!isPlaying);
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const timestamp = urlParams.get('timestamp');
    const standardId = urlParams.get('standardId');
    if (!timestamp) return;

    if (videoRef.current && timestamp && standardId) {
      window.history.pushState({}, document.title, window.location.pathname);
      addSelectedStandard(standards.find((standard) => standard.id === standardId));
      handleTimelineClick({ startTime: Number(timestamp) });
    }
  }, [videoRef.current]);

  const createStartAndEndEvents = (startTime, endTime) => {
    const events = [
      {
        id: 'start-id',
        label: 'Start',
        startTime,
        endTime: startTime,
      },
      {
        id: 'end-id',
        label: 'End',
        startTime: endTime,
        endTime,
      },
    ];
    setInitalStartAndEndEvents(events);
  };

  const playSession = () => {
    if (new Date(dataCapture.endTime).getTime() <= currentTime.getTime()) {
      resetSession();
    }

    setIsPlaying(true);
  };

  const pauseSession = () => {
    setIsPlaying(false);
  };

  const resetSession = () => {
    setIsPlaying(false);
    const startDate = new Date(dataCapture.startTime);
    setCurrentTime(startDate);
    setVideoTime(startDate);
  };

  const addSelectedStandard = (standard) => {
    activeStandards[standard.id] = standard;
    setActiveStandards(activeStandards);
    updateEvents(activeStandards);
  };

  const removeActiveStandard = (standardId) => {
    // remove the standard from the existing active standards array
    delete activeStandards[standardId];
    setActiveStandards(activeStandards);
    updateEvents(activeStandards);
  };

  const convertSensorsToSensorByTypeMap = (sensors) => {
    const keys = Object.keys(sensors);
    const sensorTypes = [...new Set(keys.map((key) => key.split(':')[1]))];

    const sensorsByType = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const sensorType of sensorTypes) {
      const sensorDataArray = keys.filter((key) => key.split(':')[1] === sensorType);
      sensorsByType[sensorType] = sensorDataArray;
    }
    setSensorsByType(sensorsByType);
  };

  const updateEvents = (activeStandards) => {
    const newEvents = createEventsFromStandards(activeStandards);
    sortEvents(newEvents);
    setSensorEvents(newEvents);
  };

  const sortEvents = (events) => {
    events.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    return events;
  };

  const createEventsFromStandards = (activeStandards) => {
    const keys = Object.keys(allSensorData);
    const sensorTypes = keys.map((key) => key.split(':')[1]);
    const eventsFromStandards = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, standard] of Object.entries(activeStandards)) {
      console.log('Key', k);
      if (sensorTypes.includes(standard.sensorType)) {
        const sensorDataArray = keys.filter((key) => key.split(':')[1] === standard.sensorType);
        sensorDataArray.forEach((sensorDataKey) => {
          const sensorData = allSensorData[sensorDataKey];
          const newEvents = getEventsFromDataAndStandards(sensorData, standard);
          const consolidated = consolidateEvents(newEvents, lagTime);
          eventsFromStandards.push(...consolidated);
        });
      }
    }
    console.log('Events from Standards: ', eventsFromStandards);
    return eventsFromStandards;
  };

  function consolidateEvents(events, lagTime) {
    // Sort events by end time
    events.sort((a, b) => new Date(a.endTime) - new Date(b.endTime));

    // Initialize variables
    const consolidatedEvents = [];
    let currentEvent = null;

    // Loop through events
    for (let i = 0; i < events.length; i += 1) {
      const event = events[i];

      // If there is no current event, set it to the current event and continue to the next event
      if (!currentEvent) {
        currentEvent = event;
        continue;
      }

      // If the current event's end time is within the lag time of the next event's start time,
      // consolidate the events and continue to the next event

      let currentEventEndTime = new Date(currentEvent.endTime);
      currentEventEndTime = currentEventEndTime.setTime(currentEventEndTime.getTime() + lagTime);

      if (new Date(currentEventEndTime) >= new Date(event.startTime)) {
        currentEvent.endTime = event.endTime;
      } else {
        // Otherwise, add the current event to the consolidated events array
        consolidatedEvents.push(currentEvent);
        currentEvent = event;
      }
    }

    // Add the last event to the consolidated events array
    if (currentEvent) {
      consolidatedEvents.push(currentEvent);
    }

    return consolidatedEvents;
  }

  const getEventsFromDataAndStandards = (sensorData, standard) => {
    const newEvents = [];
    const standardType = standard.operator;
    const standardValue = parseFloat(standard.value);

    const label = `Event: ${standard.name}`;
    let i = 0;
    while (i < sensorData.length) {
      const dataPoint = sensorData[i];
      const value = parseFloat(dataPoint.value);
      if (standardType === 'greater') {
        if (value > standardValue) {
          const event = {
            id: standard.id,
            label,
            startTime: dataPoint.time,
            endTime: dataPoint.time,
            value,
          };
          newEvents.push(event);
        }
      }
      if (standardType === 'lesser') {
        if (value < standardValue) {
          const event = {
            id: standard.id,
            label,
            startTime: dataPoint.time,
            endTime: dataPoint.time,
            value,
          };
          newEvents.push(event);
        }
      }
      i += 1;
    }
    console.log('New Event s', newEvents);
    return newEvents;
  };

  function setVideoTime(time) {
    if (
      determineDurationInVideo(time, dataCapture.startTime) > 0 &&
      determineDurationInVideo(time, dataCapture.endTime) < 0
    ) {
      videoRef.current.seekTo(determineDurationInVideo(time, dataCapture.startTime) / 1000);
    } else {
      videoRef.current.seekTo(0);
    }
  }

  function determineDurationInVideo(targetTime, ct) {
    const duration = new Date(targetTime) - new Date(ct);
    return duration;
  }

  useEffect(() => {
    async function fetchData() {
      getDataCapture(dataCaptureId).then((res) => {
        if (true) {
          // user.settings.displayTemperatureAsFahrenheit
          // Todo: Fix Sensor ID to not be null in app. Fix it so sensor id doesn't matter here
          if (res.data['null:TEMPERATURE_DATA']) {
            res.data['null:TEMPERATURE_DATA_F'] = res.data['null:TEMPERATURE_DATA'].map((obj) => ({
              ...obj,
              value: ((obj.value * 9) / 5 + 32).toFixed(2),
            }));
            delete res.data['null:TEMPERATURE_DATA'];
          }
          console.log('DataCapture', res);
        }
        setDataCapture(res);
      });
      getStreamingLink(dataCaptureId).then((res) => {
        setVideoStreamingLink(res);
        console.log('Video Streaming Link: ', res);
      });
    }
    fetchData();
  }, [dataCaptureId]);

  useEffect(() => {
    if (dataCapture.length === 0) {
      return;
    }

    console.log('Data Capture: ', dataCapture);
    if (dataCapture.data) {
      setAllSensorData(dataCapture.data);
    }
    setCurrentTime(new Date(dataCapture.startTime));

    getAllRules().then((res) => {
      console.log('Standards: ', res);
      setStandards(res);
    });
    setAllSensorData(dataCapture.data);
    const keys = Object.keys(dataCapture.data);
    const sensorTypes = keys.map((key) => key.split(':')[1]);
    convertSensorsToSensorByTypeMap(dataCapture.data);
    setSensorTypes([...new Set(sensorTypes)]);
    if (dataCapture.data) {
      createStartAndEndEvents(dataCapture.startTime, dataCapture.endTime);
      setCurrentTime(new Date(dataCapture.startTime));
      setLoading(false);
      createEventsFromStandards(activeStandards);
    }
  }, [dataCapture]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        let newTime = new Date(currentTime.getTime() + 1000);
        if (sensorEvents.length > 0 && !withinAnEvent(newTime)) {
          // find next event
          const rampUpTime = currentTime.getTime() + lagTime + 1000;
          const nextEvent = sensorEvents.find((event) => new Date(event.startTime) > rampUpTime);

          if (nextEvent) {
            setStepperActiveStep(sensorEvents.indexOf(nextEvent) + 1);
            const time = new Date(nextEvent.startTime).getTime() - lagTime;

            setVideoTime(time);
            newTime = new Date(time);
          }
        }

        setCurrentTime(newTime);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isPlaying, currentTime, sensorEvents, withinAnEvent, lagTime, setVideoTime]);

  useEffect(() => {
    if (currentTime) {
      if (currentTime >= new Date(dataCapture.endTime)) {
        pauseSession();
      }
    }
  }, [currentTime, dataCapture.endTime]);

  function withinAnEvent(time) {
    const newEvents = sensorEvents.filter(
      (event) =>
        new Date(event.startTime - lagTime) <= time && new Date(new Date(event.endTime).getTime() + lagTime) >= time
    );
    if (newEvents.length > 0) {
      return true;
    }
    return false;
  }

  const createStandard = () => {
    setCreateStandardModalShown(true);
  };

  const addNewStandard = async (newStandard) => {
    createRule(newStandard).then((res) => {
      setStandards([...standards, res]);
    });
  };

  const closeCreateStandardDialog = () => {
    setCreateStandardModalShown(false);
  };

  const handleTimelineClick = (event) => {
    let time = new Date(new Date(event.startTime).getTime() - lagTime);
    if (time < new Date(dataCapture.startTime)) {
      time = new Date(dataCapture.startTime);
    }
    setVideoTime(time);
    setCurrentTime(new Date(time));

    if (event.sensor) {
      setEventSensor(event.sensor);
    }
  };

  const updateGraphCount = (count) => {
    if (count < 0) {
      count = 0;
    }
    if (count > MAX_GRAPH_COUNT) {
      count = MAX_GRAPH_COUNT;
    }
    setGraphCount(count);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const onVideoReady = () => {
    console.log('video ready');
  };

  const onTranscriptionLoad = () => {
    retriveTranscription(dataCaptureId).then((res) => {
      console.log(res);
      setTranscription(res);
    });
  };

  return (
    <>
      <Helmet>
        <title> VPSI | {dataCapture.name} </title>
      </Helmet>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <SessionPageHeader
              lagTime={lagTime}
              lagTimeChanged={setLagTime}
              session={dataCapture}
              playing={isPlaying}
              playClicked={playSession}
              pauseClicked={pauseSession}
              resetClicked={resetSession}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <SessionPageVideoPlayer
              session={dataCapture}
              isPlaying={isPlaying}
              videoRef={videoRef}
              videoFileLocation={videoStreamingLink}
              onVideoReady={onVideoReady}
              playClicked={playSession}
              pauseClicked={pauseSession}
              resetClicked={resetSession}
              lagTime={lagTime}
              lagTimeChanged={setLagTime}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <SessionDetailsCard
              session={dataCapture}
              currentTime={currentTime}
              standards={standards}
              addSelectedStandard={addSelectedStandard}
              activeStandards={activeStandards}
              removeActiveStandard={removeActiveStandard}
              createStandard={createStandard}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <SessionEventTimeLine
              setActiveStep={setStepperActiveStep}
              stepperActiveStep={stepperActiveStep}
              activeStandards={activeStandards}
              session={dataCapture}
              onEventClick={handleTimelineClick}
              events={sortEvents([...initalStartAndEndEvents, ...sensorEvents])}
              timeObject={{
                start: new Date(initalStartAndEndEvents[0].startTime),
                end: new Date(initalStartAndEndEvents[1].endTime),
                current: currentTime,
              }}
            />
          </Grid>
          {Array(graphCount)
            .fill(null)
            .map((_, index) => (
              <Grid key={index} item xs={12} md={12} lg={12}>
                <SessionPageChartTabbedDisplay
                  key={index}
                  session={dataCapture}
                  sensorByTypes={sensorsByType}
                  events={sensorEvents}
                  currentTime={currentTime}
                  onChartClick={handleTimelineClick}
                  eventSensor={index === 0 ? eventSensor : null}
                  setSensor={setEventSensor}
                />
              </Grid>
            ))}
          <Grid item xs={12} md={12} lg={12}>
            <Button variant="contained" color="primary" onClick={() => updateGraphCount(graphCount - 1)}>
              -
            </Button>
            <Button variant="contained" color="primary" onClick={() => updateGraphCount(graphCount + 1)}>
              +
            </Button>
          </Grid>
        </Grid>
      </Container>
      <StandardCreationDialog
        open={createStandardModalShown}
        handleClose={closeCreateStandardDialog}
        handleSave={addNewStandard}
        sensorTypes={sensorTypes}
      />
    </>
  );
}
