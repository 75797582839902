import React from 'react';
import './Loading.css';

const Loading = () => (
  <div className="loading">
    <div className="spinner" />
    <div className="text">Loading...</div>
  </div>
);

export default Loading;
