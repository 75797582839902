import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import styled from 'styled-components';

SessionEventTimeLine.propTypes = {
  events: PropTypes.array,
  onEventClick: PropTypes.func,
  stepperActiveStep: PropTypes.number,
  setActiveStep: PropTypes.func,
  activeStandards: PropTypes.object,
  timeObject: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    current: PropTypes.instanceOf(Date),
  }),
};

const Grid = styled.div`
  width: 100%;
  grid-gap: 16px;
  display: grid;
  grid-template-columns: minmax(100px, min-content) auto;
  grid-template-rows: auto;
  grid-auto-rows: minmax(40px, 1fr);
`;

const StandardTitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: right;
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Line = styled.div`
  display: block;
  border-color: rgb(196, 205, 213);
  border-top-style: solid;
  border-top-width: 1px;
  width: 100%;
`;

const ProgressLine = styled.div`
  content: '';
  height: 150%;
  width: 2px;
  position: absolute;
  left: ${({ $percentage }) => $percentage}%;
  z-index: 2;
  border-left: 2px solid black;
`;

const EventBlock = styled.div`
  content: '';
  height: 115%;
  background: #79a2e3;
  border-radius: 4px;
  position: absolute;
  left: ${({ $start }) => $start}%;
  right: ${({ $end }) => $end}%;
  z-index: 1;
  cursor: pointer;

  &:hover {
    background: #8fb2e8;
  }
`;

function convertToReadableTime(ms) {
  if (ms === 0) return '0:00';
  const time = {
    hour: Math.floor(ms / 3600000) % 24,
    minute: Math.floor(ms / 60000) % 60,
    second: Math.floor(ms / 1000) % 60,
  };

  Object.entries(time).forEach((val) => {
    time[val[0]] = val[1] < 10 ? `0${val[1]}` : val[1];
  });

  const { hour, minute, second } = time;
  let returnTime;
  if (hour === '00' && minute === '00') {
    returnTime = `00:${second}`;
  } else if (hour === '00') {
    returnTime = `${minute}:${second}`;
  } else {
    returnTime = `${hour}:${minute}:${second}`;
  }

  return returnTime[0] === '0' ? returnTime.slice(1) : returnTime;
}

export default function SessionEventTimeLine({
  events,
  onEventClick,
  stepperActiveStep,
  setActiveStep,
  timeObject,
  activeStandards,
}) {
  const totalTime = timeObject.end - timeObject.start;
  const getPercentage = (time) => (((time - timeObject.start) / totalTime) * 100).toFixed(2);
  const elapsed = timeObject.current - timeObject.start;
  const percentage = getPercentage(timeObject.current);
  const watchedTime = convertToReadableTime(elapsed);
  const endTime = convertToReadableTime(totalTime);

  let steps;
  if (events === undefined) {
    steps = [];
  } else {
    steps = events;
  }

  if (steps.length === 0) {
    return <></>;
  }

  return (
    <Card>
      <CardContent>
        <Grid>
          <StandardTitle>{`${watchedTime} / ${endTime}`}</StandardTitle>
          <LineContainer>
            <Line />
            <ProgressLine $percentage={percentage} />
          </LineContainer>
          {Object.keys(activeStandards).map((standard, i) => {
            const standardEvents = events.filter((el) => el.id === activeStandards[standard].id);

            return (
              <>
                <StandardTitle key={`standard-${i}`}>{activeStandards[standard].name}</StandardTitle>
                <LineContainer key={`line-${i}`}>
                  <Line />
                  <ProgressLine $percentage={percentage} />
                  {standardEvents &&
                    standardEvents?.map((step, i) => (
                      <EventBlock
                        key={`${step.id}-${i}`}
                        $start={getPercentage(new Date(step.startTime))}
                        $end={100 - getPercentage(new Date(step.endTime))}
                        onClick={() => onEventClick({ ...step, sensor: activeStandards[standard].sensorType })}
                      />
                    ))}
                </LineContainer>
              </>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}
