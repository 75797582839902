import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import SessionListPage from './pages/SessionListPage';
import Page404 from './pages/Page404';
import StandardsPage from './pages/StandardsPage';
import AccountPage from './pages/AccountPage';
import DataCapturePage from './pages/DataCapturePage';
import CreateAccountPage from './pages/CreateAccountPage';
import RequestPasswordResetPage from './pages/RequestPasswordResetPage';
import PasswordResetPage from './pages/ResetPasswordPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <SessionListPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'account', element: <AccountPage /> },
        { path: 'standards', element: <StandardsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'sessionList', element: <SessionListPage /> },
        { path: 'session/:dataCaptureId', element: <DataCapturePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'createAccount',
      element: <CreateAccountPage />,
    },
    {
      path: 'requestPasswordReset',
      element: <RequestPasswordResetPage />,
    },
    {
      path: 'passwordReset/:token',
      element: <PasswordResetPage />,
    },
    {
      path: 'privacypolicy',
      element: <PrivacyPolicyPage />,
    },
    {
      path: 'rawprivacypolicy',
      element: (
        <iframe
          src="/assets/documents/PrivacyPolicy.md"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
