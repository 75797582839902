import axios from 'axios';

// export const API_ENDPOINT =
  // process.env.NODE_ENV === 'production' ? 'https://api.videoplussensors.com/api' : 'http://localhost:8080/api';

export const API_ENDPOINT = 'https://api.videoplussensors.com/api'; // Look at Prod locally

export const axiosInstance = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,
});

export const convertStringJsonToObject = (stringJson) => JSON.parse(stringJson.toString());
