import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';

import { Autocomplete, Card, TextField, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useChart } from '../../../components/chart';

SessionChartTab.propTypes = {
  chart: PropTypes.object,
  charts: PropTypes.array,
  events: PropTypes.array,
  currentTime: PropTypes.object,
  sensorTypes: PropTypes.array,
  tabindex: PropTypes.number,
  onChartClick: PropTypes.func,
  eventSensor: PropTypes.string,
  setSensor: PropTypes.func,
};

export default function SessionChartTab({ chart, charts, events, currentTime, onChartClick, eventSensor, setSensor }) {
  const [displayChart, setDisplayChart] = useState(chart);

  useEffect(() => {
    if (eventSensor) {
      const selectedChart = charts.find((chart) => chart.data[0].name === `null:${eventSensor}`);
      setDisplayChart(selectedChart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventSensor]);

  function createCurrentTimeAnnotation(currentTime) {
    return {
      x: new Date(currentTime).getTime(),
      borderColor: '#775DD0',
      label: {
        borderColor: '#775DD0',
        style: {
          color: '#fff',
          background: '#775DD0',
        },
        text: 'Current Time',
      },
    };
  }

  function createAnnotation(startTime, label, endTime) {
    return {
      x: new Date(startTime).getTime(),
      x2: new Date(endTime).getTime(),
      label: {
        text: label,
      },
      fillColor: '#d50100',
    };
  }

  function changeChart(_, newValue) {
    const selectedChart = charts.find((chart) => chart.name === newValue);
    setDisplayChart(selectedChart);
    setSensor(null);
  }

  function getChart() {
    const annotations = events.map((event) => createAnnotation(event.startTime, event.label, event.endTime));
    annotations.push(createCurrentTimeAnnotation(currentTime));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const chartOptions = useChart({
      plotOptions: { bar: { columnWidth: '16%' } },
      fill: { type: displayChart.data.map((i) => i.fill) },
      labels: displayChart.labels,
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
        },
      },
      annotations: {
        xaxis: [...annotations],
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            pan: true,
            zoom: true,
          },
        },
        events: {
          markerClick: (_, chartContext, opts) => {
            onChartClick({ startTime: displayChart.labels[opts.dataPointIndex] });
          },
        },
      },
    });

    return (
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={displayChart.data} options={chartOptions} height={364} />
      </Box>
    );
  }

  const chartNames = charts.map((chart) => chart.name);

  return (
    <div>
      <Card>
        <br />
        <Autocomplete
          disablePortal
          id="combo-box-sensortype"
          options={chartNames}
          defaultValue={chartNames[0]}
          sx={{ width: 300 }}
          onChange={(event, newValue) => {
            changeChart(event, newValue);
          }}
          value={displayChart.name}
          renderInput={(params) => <TextField {...params} label="Sensor Type" />}
        />
        {displayChart !== undefined && getChart(displayChart.data)}
        {displayChart === undefined && (
          <Typography sx={{ height: 300 }} variant="h6" align="center">
            No charts to display
          </Typography>
        )}
        <br />
      </Card>
    </div>
  );
}
