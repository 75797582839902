import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Divider } from '@aws-amplify/ui-react';
import { StandardChipDisplay } from '../standard-chip-display';

RuleSelectionScreen.propTypes = {
  standards: PropTypes.array,
  activeStandards: PropTypes.object,
  addActiveStandard: PropTypes.func,
  removeActiveStandard: PropTypes.func,
  createStandard: PropTypes.func,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

function RuleSelectionScreen(props) {
  const [seeLoadingSymbol, setSeeLoadingSymbol] = useState(false);

  const onDelete = (standardId) => {
    props.removeActiveStandard(standardId);
    setSeeLoadingSymbol(true);
  };

  useEffect(() => {
    window.setTimeout(() => {
      setSeeLoadingSymbol(false);
    }, 500);
  }, [seeLoadingSymbol]);

  const availableStandardColor = 'primary';

  const activeStandardColor = 'success';
  const test = (starndard) => {
    props.addActiveStandard(starndard);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle>Select Rule to Apply</DialogTitle>
      <DialogContent>
        <Button variant="outlined" size="small" onClick={props.createStandard}>
          Create Rule
        </Button>
        <br />
        <StandardChipDisplay standards={props.standards} onClick={test} color={availableStandardColor} />
        <br />
        <br />
        <Divider />
        <br />
        <StandardChipDisplay
          standards={props.activeStandards}
          onClick={onDelete}
          onDelete={onDelete}
          color={activeStandardColor}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RuleSelectionScreen;
