import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography, Button } from '@mui/material';
// components
import { StandardsList, StandardCreationDialog } from '../sections/@dashboard/standards';
// mock
import { getSignedInUser } from '../services/authService';
import { createRule, deleteRule, getAllRules } from '../services/ruleService';
import { getSensorType } from 'src/services/localizationService';
import StandardEditDialog from 'src/sections/@dashboard/standards/StandardEditingDialog';

// ----------------------------------------------------------------------

export default function StandardsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const user = getSignedInUser();

  const sensorTypes = [
    '0.3_PER_100_CM^3_DATA',
    '0.5_PER_100_CM^3_DATA',
    '1.0_PER_100_CM^3_DATA',
    '5.0_PER_100_CM^3_DATA',
    '10.0_PER_100_CM^3_DATA',
    'CO2_DATA',
    'HUMIDITY_DATA',
    'PM1.0_ULTRA_FINE_PARTICULATES_DATA',
    'PM2.5_FINE_PARTICLES_DATA',
    'PM10.0_DATA',
    user.displayTemperatureAsFahrenheit ? 'TEMPERATURE_DATA_F' : 'TEMPERATURE_DATA',
    'TVOC_DATA',
  ];

  const [showCreateStandardDialog, setShowCreateStandardDialog] = useState(false);
  const [showEditStandardDialog, setShowEditStandardDialog] = useState(false);
  const [editRule, setEditRule] = useState({});

  const [standards, setStandards] = useState([]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const loadPage = async () => {
    const allStandards = await getAllRules();
    setStandards(allStandards);
  };

  const addStandard = async (standard) => {
    const newStandard = await createRule(standard);
    const idx = standards.findIndex(({ id }) => id === standard.id);
    if (idx !== -1) {
      const newStandards = [...standards];
      newStandards[idx] = standard;
      setStandards(newStandards);
      return;
    }
    setStandards([...standards, newStandard]);
  };

  const deleteStandard = async (standardId) => {
    await deleteRule(standardId);
    const idx = standards.findIndex(({ id }) => id === standardId);

    if (idx !== -1) {
      const newStandards = [...standards];
      newStandards.splice(idx, 1);
      setStandards(newStandards);
      return;
    }
  };

  const showCreationDialog = () => {
    setShowCreateStandardDialog(true);
  };

  const showEditDialog = (rule) => {
    setShowEditStandardDialog(true);
    setEditRule(rule);
  };

  useEffect(() => {
    console.log('StandardsPage');
    loadPage();
  }, []);

  return (
    <>
      <Helmet>
        <title>VPSI | Rules</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Rules
        </Typography>
        <Button variant="contained" onClick={showCreationDialog} sx={{ mb: 5 }}>
          New
        </Button>
        <StandardsList standards={standards} onEdit={showEditDialog} />
      </Container>
      <StandardCreationDialog
        open={showCreateStandardDialog}
        handleClose={() => setShowCreateStandardDialog(false)}
        handleSave={addStandard}
        sensorTypes={sensorTypes}
      />
      <StandardEditDialog
        open={showEditStandardDialog}
        handleClose={() => setShowEditStandardDialog(false)}
        handleSave={addStandard}
        sensorTypes={sensorTypes}
        standardData={editRule}
        handleDelete={deleteStandard}
      />
    </>
  );
}
