// routes
import React from 'react';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
// ----------------------------------------------------------------------

function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}

export default App;
