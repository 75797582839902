import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// components
import Iconify from '../../../components/iconify';

import { login, createAccount } from '../../../services/authService';
import { errorNotification } from '../../../services/notificationService';
// ----------------------------------------------------------------------

export default function CreateAccountForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [username, setUserName] = useState(false);
  const [emailAddress, setEmailAddress] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const callAuthCreateAccount = (e) => {
    e.preventDefault();

    if (!isValidInput()) {
      return;
    }
    createAccount(username, emailAddress, password)
      .then((response) => {
        if (response.message === 'User registered successfully!') {
          callAuthLogin();
        } else {
          errorNotification('Please Check your details and try again');
        }
      })
      .catch((error) => {
        errorNotification(`Creating Account Failed failed:  ${error}`);
      });
  };

  const isValidInput = () => {
    if (username === '' || emailAddress === '' || password === '' || confirmPassword === '') {
      errorNotification('Please fill in all fields');
      return false;
    }
    if (password !== confirmPassword) {
      errorNotification('Passwords do not match');
      return false;
    }
    return true;
  };

  const callAuthLogin = () => {
    login(username, password).then((response) => {
      if (response) {
        console.log('Login successful');
        navigate('/dashboard/app', { replace: true });
      }
    });
  };

  return (
    <>
      <form onSubmit={callAuthCreateAccount}>
        <Stack spacing={3}>
          <TextField name="username" label="Username" onChange={(e) => setUserName(e.target.value)} />
          <TextField name="email" label="Email address" onChange={(e) => setEmailAddress(e.target.value)} />
          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            name="password"
            label="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Stack>

        <br />
        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Create Account
        </LoadingButton>
      </form>
      <ToastContainer />
    </>
  );
}
