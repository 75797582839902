import React from 'react';
import PropTypes from 'prop-types';
import { Card, Stack, Typography } from '@mui/material';

SessionPageHeader.propTypes = {
  session: PropTypes.object,
};

function SessionPageHeader({ session }) {
  const startTime = new Date(session.startTime);
  const formattedStartTime = `${startTime.toLocaleDateString()} ${startTime.toLocaleTimeString()}`;
  const endTime = new Date(session.endTime);
  const formattedEndTime = `${endTime.toLocaleDateString()} ${endTime.toLocaleTimeString()}`;

  return (
    <Card>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 3 }}>
        <Typography variant="h3">{session.name}</Typography>
        <Stack direction="column" spacing={1}>
          <Typography variant="p" textAlign={'right'}>
            Start Time: {formattedStartTime}
          </Typography>
          <Typography variant="p" textAlign={'right'}>
            End Time: {formattedEndTime}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

export default SessionPageHeader;
